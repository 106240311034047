import { Component, Input, OnInit } from "@angular/core";
import { ModalController, AlertController, ToastController } from "@ionic/angular";

interface Entry {
  id: number;
  time: string;
  bdDetail: string;
  problem: string;
  isEditing?: boolean;
}

interface Entry2 {
  Details: string;
  Resp: string;
  Target: string;
  Actual: string;
  isEditing?: boolean;
}

interface Entry3 {
  Details: string;
  Resp: string;
  Target: string;
  Actual: string;
  isEditing?: boolean;
}

@Component({
  selector: "app-edit-notification",
  templateUrl: "./edit-notification.component.html",
  styleUrls: ["./edit-notification.component.scss"],
})
export class EditNotificationComponent implements OnInit {
  @Input() notificationData: any;
  @Input() longBreakdownData: any;

  // Form fields
  notificationType: string;
  selectedEquipment: string;
  startLinestop: string;
  esaRelated: string;
  equipmentPartSelected: string;
  breakdownDescription: string;
  followUp: string;

  // Breakdown specific fields
  totalLinestopEntered: string;
  totalLinestopSelected: string = "Min";
  equipStopEntered: string;
  equipstopSelected: string = "Min";
  equipmentProblemSelected: string;
  directCauseSelected: string;
  machineFaultCode: string;
  recoverySelected: string;
  recoveryComments: string;
  completedTime: string;

  // Add these properties if not already present
  reportedBy: string;
  updatedBy: string;
  reportedDate: string;
  updatedDate: string;

  // if the noti has LongBreakdownData, set the values
  isTherelongBreakdownData: any;

  // Add these new properties
  equipmentNumberList: { number: string; description: string }[] = [
    { number: "12345", description: "Equip-X-1-Prem" },
    { number: "67890", description: "Zone-learn-2" },
    { number: "54321", description: "Test Equipment 3" },
    { number: "09876", description: "Test Equipment 4" },
    { number: "11223", description: "Hydraulic Pump" },
    { number: "33445", description: "Conveyor Belt - Model A" },
    { number: "55667", description: "Cooling System CX-9" },
    { number: "77889", description: "Machining Center V2" },
    { number: "99001", description: "Welding Robot RX-3" },
    { number: "22334", description: "Press Machine - Type Z" },
    // ... other equipment
  ];

  filteredEquipmentNumber: { number: string; description: string }[] = [];
  showEquipmentList: boolean = false;

  // Add suggestion lists for dropdowns
  equipmentPartList: string[] = [
    "Electrical",
    "Mechanical",
    "Pneumatic",
    "Hydraulic",
    "Control System",
    "Safety System",
    "Lubrication System",
    "Cooling System",
    "Heating System",
    "Conveyor System",
    "Robotic Arm",
    "Sensor System",
    "Power Supply",
    "Network System",
  ];

  equipmentProblemList: string[] = [
    "Broken",
    "Not Working",
    "Leaking",
    "Overheating",
    "Vibrating",
    "Noisy",
    "Slow Response",
    "Incorrect Reading",
    "Connection Issue",
    "Power Issue",
    "Calibration Required",
    "Wear and Tear",
    "Blocked",
    "Jammed",
    "Misaligned",
  ];

  directCauseList: string[] = [
    "Operator Error",
    "Material Issue",
    "Machine Issue",
    "Environmental Factor",
    "Maintenance Required",
    "Power Failure",
    "Network Issue",
    "Software Bug",
    "Hardware Failure",
    "Wear and Tear",
    "External Damage",
    "Calibration Required",
    "Safety System Trigger",
    "Emergency Stop",
    "System Overload",
  ];

  recoveryList: string[] = [
    "Replace",
    "Repair",
    "Recover",
    "Rebuild",
    "Adjust",
    "Clean",
    "Calibrate",
    "Reset",
    "Update",
    "Upgrade",
    "Modify",
    "Reconfigure",
    "Reinstall",
    "Restart",
    "Reconnect",
  ];

  // Add filtered lists
  filteredEquipmentPart: string[] = [];
  filteredEquipmentProblem: string[] = [];
  filteredDirectCause: string[] = [];
  filteredRecovery: string[] = [];

  // Add visibility flags
  showEquipmentPartList: boolean = false;
  showEquipmentProblemList: boolean = false;
  showDirectCauseList: boolean = false;
  showRecoveryList: boolean = false;

  constructor(
    private modalCtrl: ModalController,
    private alertController: AlertController,
    private toastController: ToastController
  ) {}

  ngOnInit() {
    this.loadNotificationData();
  }

  loadNotificationData() {
    if (!this.notificationData) return;

    // Set common fields
    this.notificationType = this.notificationData.NotificationType;
    this.selectedEquipment = this.notificationData.Equipment;
    this.startLinestop = this.notificationData.StartLinestop;
    this.esaRelated = this.notificationData.ESARelated;
    this.breakdownDescription = this.notificationData.BreakdownDescription;
    this.equipmentPartSelected = this.notificationData.EquipmentPart;
    this.followUp = this.notificationData.FollowUp || "No";

    // Set breakdown-specific fields if applicable
    if (this.notificationType === "Breakdown") {
      // Parse the time values from combined strings
      if (this.notificationData.TotalLinestop) {
        const [value, unit] = this.notificationData.TotalLinestop.split(" ");
        this.totalLinestopEntered = value;
        this.totalLinestopSelected = unit || "Min"; // Default to 'Min' if no unit
      }

      if (this.notificationData.EquipStop) {
        const [value, unit] = this.notificationData.EquipStop.split(" ");
        this.equipStopEntered = value;
        this.equipstopSelected = unit || "Min"; // Default to 'Min' if no unit
      }

      this.equipmentProblemSelected = this.notificationData.EquipmentProblem;
      this.directCauseSelected = this.notificationData.DirectCause;
      this.machineFaultCode = this.notificationData.MachineFaultCode;
      this.recoverySelected = this.notificationData.Recovery;
      this.recoveryComments = this.notificationData.RecoveryDescription;
      this.completedTime = this.notificationData.CompletedTime;

      // Handle long breakdown data if available
      this.isTherelongBreakdownData = this.longBreakdownData;
      if (this.longBreakdownData) {
        this.loadLongBreakdownData(this.longBreakdownData);
      }
    }

    // Add these new assignments
    this.reportedBy = this.notificationData.CREATEDBY;
    this.updatedBy = this.notificationData.UpdatedBy;
    this.reportedDate =
      this.notificationData.CreatedDate || this.notificationData.StartLinestop;
    this.updatedDate =
      this.notificationData.UpdatedDate || this.notificationData.StartLinestop;

    // Parse the date strings properly
    if (this.startLinestop) {
      // Handle ISO date string format
      const date = new Date(this.startLinestop);
      this.startLinestop = date.toISOString();
    }

    if (this.completedTime) {
      const date = new Date(this.completedTime);
      this.completedTime = date.toISOString();
    }

    // Extract equipment number and description if they exist
    const equipmentParts = this.notificationData.Equipment.split(" - ");
    if (equipmentParts.length > 0) {
      this.selectedEquipment = this.notificationData.Equipment;
    }
  }

  async dismiss() {
    const alert = await this.alertController.create({
      header: "Confirm Cancel",
      message:
        "Are you sure you want to cancel? Any unsaved changes will be lost.",
      buttons: [
        {
          text: "No, Continue Editing",
          role: "cancel",
        },
        {
          text: "Yes, Cancel",
          role: "confirm",
          handler: () => {
            this.modalCtrl.dismiss();
          },
        },
      ],
    });

    await alert.present();
  }

  async saveChanges() {
    const updatedData = {
      ...this.notificationData,
      NotificationType: this.notificationType,
      Equipment: this.selectedEquipment,
      EquipmentPart: this.equipmentPartSelected,
      StartLinestop: this.startLinestop,
      TotalLinestop: `${this.totalLinestopEntered} ${this.totalLinestopSelected}`,
      EquipStop: `${this.equipStopEntered} ${this.equipstopSelected}`,
      DirectCause: this.directCauseSelected,
      EquipmentProblem: this.equipmentProblemSelected,
      MachineFaultCode: this.machineFaultCode,
      Recovery: this.recoverySelected,
      CompletedTime: this.completedTime,
      ESARelated: this.esaRelated,
      FollowUp: this.followUp,
      RecoveryDescription: this.recoveryComments,
      BreakdownDescription: this.breakdownDescription,
      CREATEDBY: this.reportedBy,
      UpdatedBy: this.updatedBy,
      CreatedDate: this.reportedDate,
      UpdatedDate: new Date().toISOString(), // Update the last modified date
    };

    this.modalCtrl.dismiss(updatedData);
  }

  // Add these methods
  onSearchInput(event: any) {
    const searchTerm = event.target.value.toLowerCase();
    if (searchTerm === "") {
      this.filteredEquipmentNumber = this.equipmentNumberList;
      this.showEquipmentList = true;
    } else {
      this.filteredEquipmentNumber = this.equipmentNumberList.filter(
        (equipment) =>
          equipment.number.toLowerCase().includes(searchTerm) ||
          equipment.description.toLowerCase().includes(searchTerm)
      );
      this.showEquipmentList = true;
    }
  }

  showAllEquipment() {
    this.showEquipmentList = true;
    this.filteredEquipmentNumber = this.equipmentNumberList;
  }

  onSuggestionSelect(suggestion: { number: string; description: string }) {
    this.selectedEquipment = `${suggestion.number} - ${suggestion.description}`;
    this.filteredEquipmentNumber = [];
    this.showEquipmentList = false;
  }

  // Add methods for showing all items
  showAllEquipmentParts() {
    this.showEquipmentPartList = true;
    this.filteredEquipmentPart = this.equipmentPartList;
  }

  showAllEquipmentProblems() {
    this.showEquipmentProblemList = true;
    this.filteredEquipmentProblem = this.equipmentProblemList;
  }

  showAllDirectCauses() {
    this.showDirectCauseList = true;
    this.filteredDirectCause = this.directCauseList;
  }

  showAllRecovery() {
    this.showRecoveryList = true;
    this.filteredRecovery = this.recoveryList;
  }

  // Update the input handlers to show all items when empty
  onEquipmentPartInput(event: any) {
    const searchTerm = event.target.value.toLowerCase();
    if (searchTerm === "") {
      this.filteredEquipmentPart = this.equipmentPartList;
      this.showEquipmentPartList = true;
    } else {
      this.filteredEquipmentPart = this.equipmentPartList.filter((part) =>
        part.toLowerCase().includes(searchTerm)
      );
      this.showEquipmentPartList = true;
    }
  }

  onEquipmentProblemInput(event: any) {
    const searchTerm = event.target.value.toLowerCase();
    if (searchTerm === "") {
      this.filteredEquipmentProblem = this.equipmentProblemList;
      this.showEquipmentProblemList = true;
    } else {
      this.filteredEquipmentProblem = this.equipmentProblemList.filter(
        (problem) => problem.toLowerCase().includes(searchTerm)
      );
      this.showEquipmentProblemList = true;
    }
  }

  onDirectCauseInput(event: any) {
    const searchTerm = event.target.value.toLowerCase();
    if (searchTerm === "") {
      this.filteredDirectCause = this.directCauseList;
      this.showDirectCauseList = true;
    } else {
      this.filteredDirectCause = this.directCauseList.filter((cause) =>
        cause.toLowerCase().includes(searchTerm)
      );
      this.showDirectCauseList = true;
    }
  }

  onRecoveryInput(event: any) {
    const searchTerm = event.target.value.toLowerCase();
    if (searchTerm === "") {
      this.filteredRecovery = this.recoveryList;
      this.showRecoveryList = true;
    } else {
      this.filteredRecovery = this.recoveryList.filter((recovery) =>
        recovery.toLowerCase().includes(searchTerm)
      );
      this.showRecoveryList = true;
    }
  }

  // Add methods for selecting suggestions
  onEquipmentPartSelect(part: string) {
    this.equipmentPartSelected = part;
    this.filteredEquipmentPart = [];
    this.showEquipmentPartList = false;
  }

  onEquipmentProblemSelect(problem: string) {
    this.equipmentProblemSelected = problem;
    this.filteredEquipmentProblem = [];
    this.showEquipmentProblemList = false;
  }

  onDirectCauseSelect(cause: string) {
    this.directCauseSelected = cause;
    this.filteredDirectCause = [];
    this.showDirectCauseList = false;
  }

  onRecoverySelect(recovery: string) {
    this.recoverySelected = recovery;
    this.filteredRecovery = [];
    this.showRecoveryList = false;
  }

  ///
  ///
  ///Edit Breakdown Report if exists
  ///
  ///
  isEditingLongBreakdown: any = false;

  entryNumber: string = "";
  bdDetail: string = "";
  problem: string = "";

  tableData: Array<{ entryNumber: string; bdDetail: string; problem: string }> =
    [];

  detail: string = "";
  res: string = "";
  target: string = "";
  actual: string = "";

  quickRepair: Array<{
    detail: string;
    res: string;
    target: string;
    actual: string;
  }> = [];

  detailSC: string = "";
  resSC: string = "";
  targetSC: string = "";
  actualSC: string = "";

  systemCounterMes: Array<{
    detailSC: string;
    resSC: string;
    targetSC: string;
    actualSC: string;
  }> = [];

  equipmentProblem: string = "";
  equipmentDirectCause: string = "";
  equipment2Why: string = "";
  equipment3Why: string = "";
  equipment4Why: string = "";
  equipmentRootCause: string = "";

  durationProblem: string = "";
  durationDirectCause: string = "";
  duration2Why: string = "";
  duration3Why: string = "";
  duration4Why: string = "";
  durationRootCause: string = "";

  safetyProblem: string = "";
  safetyDirectCause: string = "";
  safety2Why: string = "";
  safety3Why: string = "";
  safety4Why: string = "";
  safetyRootCause: string = "";

  otherChecked: boolean = false;
  otherText: string = "";

  checkboxList: Array<{ checked: boolean; text: string }> = [
    { checked: false, text: "Training (procedure, JES, SWC, falutly repair)" },
    { checked: false, text: "PM/TPM" },
    { checked: false, text: "Spare Part" },
    { checked: false, text: "Design" },
  ];

  editingEntry: Entry | null = null;
  editingEntry2: Entry2 | null = null;
  editingEntry3: Entry3 | null = null;

  isEditMode: boolean = false;

  loadLongBreakdownData(data: any) {
    console.log("Loading data:", data);

    // Equipment Analysis
    this.equipmentProblem = data.equipmentProblem || "";
    this.equipmentDirectCause = data.equipmentDirectCause || "";
    this.equipment2Why = data.equipment2Why || "";
    this.equipment3Why = data.equipment3Why || "";
    this.equipment4Why = data.equipment4Why || "";
    this.equipmentRootCause = data.equipmentRootCause || "";

    // Duration Analysis
    this.durationProblem = data.durationProblem || "";
    this.durationDirectCause = data.durationDirectCause || "";
    this.duration2Why = data.duration2Why || "";
    this.duration3Why = data.duration3Why || "";
    this.duration4Why = data.duration4Why || "";
    this.durationRootCause = data.durationRootCause || "";

    // Safety Analysis
    this.safetyProblem = data.safetyProblem || "";
    this.safetyDirectCause = data.safetyDirectCause || "";
    this.safety2Why = data.safety2Why || "";
    this.safety3Why = data.safety3Why || "";
    this.safety4Why = data.safety4Why || "";
    this.safetyRootCause = data.safetyRootCause || "";

    // Load entries if they exist
    if (data.bdReportTable) {
      this.entries = data.bdReportTable;
    }
    if (data.quickRepair) {
      this.entries2 = data.quickRepair;
    }
    if (data.systemCounterMes) {
      this.entries3 = data.systemCounterMes;
    }

    if (data.checkboxList) {
      this.checkboxList = data.checkboxList;
    }

    console.log("Data loaded successfully");
  }


  currentEntry: Entry = {
    id: 0,
    time: "",
    bdDetail: "",
    problem: "",
  };

  entries: Entry[] = [];

  addEntry() {
    if (
      this.currentEntry.time &&
      this.currentEntry.bdDetail &&
      this.currentEntry.problem
    ) {
      this.entries.push({
        ...this.currentEntry,
        id: Date.now(),
      });
      // Reset the form
      this.currentEntry = {
        id: 0,
        time: "",
        bdDetail: "",
        problem: "",
      };
    } else {
      this.presentToast("Please fill all fields before adding!");
    }
  }

  removeEntry(id: number) {
    this.entries = this.entries.filter((entry) => entry.id !== id);
  }

  currentEntry2: Entry2 = {
    Details: "",
    Resp: "",
    Target: "",
    Actual: "",
  };

  entries2: Entry2[] = [];

  addEntry2() {
    if (
      this.currentEntry2.Details &&
      this.currentEntry2.Resp &&
      this.currentEntry2.Target
    ) {
      ///format target date time to mm/dd/yyyy hh:mm
      let targetDate = new Date(this.currentEntry2.Target);
      let formattedTargetDate = targetDate.toLocaleString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      });
      this.currentEntry2.Target = formattedTargetDate;

      ///format actual date time to mm/dd/yyyy hh:mm
      let actualDate = new Date(this.currentEntry2.Actual);
      let formattedActualDate = actualDate.toLocaleString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      });
      this.currentEntry2.Actual = formattedActualDate;

      this.entries2.push({
        ...this.currentEntry2,
      });
      // Reset the form
      this.currentEntry2 = {
        Details: "",
        Resp: "",
        Target: "",
        Actual: "",
      };
    } else {
      this.presentToast("Please fill all fields before adding!");
    }
  }

  removeEntry2(Details: string) {
    this.entries2 = this.entries2.filter((entry) => entry.Details !== Details);
  }

  currentEntry3: Entry3 = {
    Details: "",
    Resp: "",
    Target: "",
    Actual: "",
  };

  entries3: Entry3[] = [];

  addEntry3() {
    if (
      this.currentEntry3.Details &&
      this.currentEntry3.Resp &&
      this.currentEntry3.Target
    ) {
      ///format target date time to mm/dd/yyyy hh:mm
      let targetDate = new Date(this.currentEntry3.Target);
      let formattedTargetDate = targetDate.toLocaleString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      });
      this.currentEntry3.Target = formattedTargetDate;

      ///format actual date time to mm/dd/yyyy hh:mm
      let actualDate = new Date(this.currentEntry3.Actual);
      let formattedActualDate = actualDate.toLocaleString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      });
      this.currentEntry3.Actual = formattedActualDate;
      this.entries3.push({
        ...this.currentEntry3,
      });
      // Reset the form
      this.currentEntry3 = {
        Details: "",
        Resp: "",
        Target: "",
        Actual: "",
      };
    } else {
      this.presentToast("Please fill all fields before adding!");
    }
  }

  removeEntry3(Details: string) {
    this.entries3 = this.entries3.filter((entry) => entry.Details !== Details);
  }

  editEntry(entry: Entry) {
    if (!entry) return;
    entry.isEditing = true;
    this.editingEntry = { ...entry };
  }

  saveEdit(entry: Entry) {
    if (!entry || !this.editingEntry) return;
    const index = this.entries.findIndex((e) => e.id === entry.id);
    if (index !== -1) {
      this.entries[index] = { ...this.editingEntry, isEditing: false };
    }
    this.editingEntry = null;
  }

  cancelEdit(entry: Entry) {
    entry.isEditing = false;
    this.editingEntry = null;
  }

  editEntry2(entry: Entry2) {
    if (!entry) return;
    entry.isEditing = true;
    this.editingEntry2 = { ...entry };
  }

  saveEdit2(entry: Entry2) {
    if (!entry || !this.editingEntry2) return;
    const index = this.entries2.findIndex((e) => e.Details === entry.Details);
    if (index !== -1) {
      this.entries2[index] = { ...this.editingEntry2, isEditing: false };
    }
    this.editingEntry2 = null;
  }

  cancelEdit2(entry: Entry2) {
    entry.isEditing = false;
    this.editingEntry2 = null;
  }

  editEntry3(entry: Entry3) {
    if (!entry) return;
    entry.isEditing = true;
    this.editingEntry3 = { ...entry };
  }

  saveEdit3(entry: Entry3) {
    if (!entry || !this.editingEntry3) return;
    const index = this.entries3.findIndex((e) => e.Details === entry.Details);
    if (index !== -1) {
      this.entries3[index] = { ...this.editingEntry3, isEditing: false };
    }
    this.editingEntry3 = null;
  }

  cancelEdit3(entry: Entry3) {
    entry.isEditing = false;
    this.editingEntry3 = null;
  }


  async presentToast(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000,
    });
    toast.present();
  }


  editLongBreakdown() {
    this.isEditingLongBreakdown = true;
  }

  cancelLongBreakdown() {
    this.isEditingLongBreakdown = false;
  }
}
