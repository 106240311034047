import { Component, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-ion-popover',
  templateUrl: './ion-popover.component.html',
  styleUrls: ['./ion-popover.component.scss'],
})
export class IonPopoverComponent {
  @Input() isNewRequest: boolean = false;

  constructor(
    private popoverController: PopoverController
  ) { }

  onSelectedPopover(value: string) {
    this.popoverController.dismiss(value);
  }
}
